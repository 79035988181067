import { Injectable } from '@angular/core'
import { Asset, createClient, Entry, EntryFields } from 'contentful'
import { environment } from '../../environments/environment'

export type CourseLevel = 'I' | 'II' | 'III' | 'Materialkurs' | 'Literaturkreis'

export type CoursePart_ =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'Materialkurs'
  | 'Literaturkreis'

export interface Course {
  workingTitle: EntryFields.Text
  level: CourseLevel
  description?: EntryFields.RichText
  parts?: Entry<CoursePart>[]
  lecturers?: Entry<Staff>[]
  location?: Entry<EventLocation>
  locations?: Entry<EventLocation>[]
  registrationContact?: Entry<CourseRegistrationContact>
  documents?: Asset[]
}

export interface CoursePart {
  part: CoursePart_
  description?: EntryFields.RichText
  start?: EntryFields.Date
  end?: EntryFields.Date
}

export interface Staff {
  name: EntryFields.Text
}

export interface CourseRegistrationContact {
  name: EntryFields.Text
  street?: EntryFields.Text
  postCode?: EntryFields.Text
  city?: EntryFields.Text
  country?: EntryFields.Text
  email?: EntryFields.Text
  phone?: EntryFields.Text
}

export interface EventLocation {
  name: EntryFields.Text
  location?: EntryFields.Location
  street?: EntryFields.Text
  postCode?: EntryFields.Text
  city?: EntryFields.Text
  country?: EntryFields.Text
}

export interface CoursesPage {
  courses?: Entry<Course>[]
}

@Injectable({ providedIn: 'root' })
export class ContentService {
  private client = createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.accessToken,
  })

  getCoursesPage(): Promise<Entry<CoursesPage>> {
    return this.client.getEntry(environment.contentful.coursesPageId, {
      include: 3,
    })
  }
}
